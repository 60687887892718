<template>
    <v-row justify="center">
        <v-col cols="12" md="8">
            <v-row class="row-my-area">   
                <v-col cols="12" md="6" class="title-pix ml-10 ml-md-0 pb-0" > 
                    <v-row class="title">
                        Minha área Pix
                    </v-row>                
                    <v-row class="text text-register">
                        Tudo o que você precisa para <br>pagar, transferir ou cobrar. <br><br>                                
                    </v-row>
                </v-col>       
                <v-col cols="12" md="6" class="icon-pix ml-8 ml-md-0">
                    <img src="@/assets/icon-pix-my-area.png">
                </v-col>
                <v-col md="12" class="pl-0 pr-0" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>    
                <v-col md="6" cols="12" class="pt-0 pb-0 pl-md-0 pr-md-0"> 
                    <v-col cols="12" md="12" class="pl-md-0" v-if="hasPermissionPayment">
                        <v-col class="select-cards-open card-myArea pl-4 pr-4" justify="center">
                            <v-row align="center">
                                <v-col class="col-2">
                                    <img src="@/assets/icon-pagar.png">
                                </v-col>
                                <v-col class="col-8">
                                    Pagar
                                </v-col>
                                <v-col class="col-2">
                                    <img src="@/assets/arrow-next.png">
                                </v-col>
                            </v-row>                    
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="12" class="pl-md-0 cursor" v-if="$vuetify.breakpoint.mdAndUp && hasPermissionPaymentCharge"
                        @click="$router.push(`/customer/digitalAccount/pix/charge`)">
                        <v-col class="select-cards-open card-myArea pl-4 pr-4" justify="center">
                            <v-row align="center">
                                <v-col class="col-2">
                                    <img src="@/assets/icone_cobrar.png">
                                </v-col>
                                <v-col class="col-8">
                                    Cobrar
                                </v-col>
                                <v-col class="col-2">
                                    <img src="@/assets/arrow-next.png">
                                </v-col>
                            </v-row>                    
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="12" class="pr-md-0 cursor" v-if="hasPermissionTransfer && $vuetify.breakpoint.mdAndDown"
                        @click="$router.push(`/customer/digitalAccount/pix/transfer`)">
                        <v-col class="select-cards-open card-myArea pl-4 pr-4" justify="center">
                            <v-row align="center">
                                <v-col class="col-2">
                                    <img src="@/assets/icone_transferis.png">
                                </v-col>
                                <v-col class="col-8">
                                    Transferir
                                </v-col>
                                <v-col class="col-2">
                                    <img src="@/assets/arrow-next.png">
                                </v-col>
                            </v-row>                    
                        </v-col>
                    </v-col>
                </v-col>
                <v-col md="6" cols="12" class="pt-0 pb-0 pl-md-0 pr-md-0 cursor"> 
                    <v-col cols="12" md="12" class="pr-md-0" v-if="$vuetify.breakpoint.mdAndUp && hasPermissionTransfer" 
                        @click="$router.push(`/customer/digitalAccount/pix/transfer`)">
                        <v-col class="select-cards-open card-myArea pl-4 pr-4" justify="center">
                            <v-row align="center">
                                <v-col class="col-2">
                                    <img src="@/assets/icone_transferis.png">
                                </v-col>
                                <v-col class="col-8">
                                    Transferir
                                </v-col>
                                <v-col class="col-2">
                                    <img src="@/assets/arrow-next.png">
                                </v-col>
                            </v-row>                    
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="12" class="pl-md-0 cursor" v-if="!$vuetify.breakpoint.mdAndUp && hasPermissionPaymentCharge" 
                        @click="$router.push(`/customer/digitalAccount/pix/charge`)">
                        <v-col class="select-cards-open card-myArea pl-4 pr-4" justify="center">
                            <v-row align="center">
                                <v-col class="col-2">
                                    <img src="@/assets/icone_cobrar.png">
                                </v-col>
                                <v-col class="col-8">
                                    Cobrar
                                </v-col>
                                <v-col class="col-2">
                                    <img src="@/assets/arrow-next.png">
                                </v-col>
                            </v-row>                    
                        </v-col>
                    </v-col>
                    <v-col md="12" class="pl-3 pr-3" v-if="$vuetify.breakpoint.mdAndDown">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" v-if="hasPermissionKeys" md="12" class="pr-md-0 cursor" 
                        @click="$router.push(`/customer/digitalAccount/pix/myKeys`)">
                        <v-col class="select-cards-open card-myArea pl-4 pr-4" justify="center">
                            <v-row align="center">
                                <v-col class="col-2">
                                    <img src="@/assets/icone_chave.png">
                                </v-col>
                                <v-col class="col-8">
                                    Minhas Chaves
                                </v-col>
                                <v-col class="col-2">
                                    <img src="@/assets/arrow-next.png">
                                </v-col>
                            </v-row>                    
                        </v-col>
                    </v-col>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import roles from '../../../config/permissions';

export default {
  data() {
      return {
        hasPermissionKeys: false,
        hasPermissionPayment: false,
        hasPermissionPaymentCharge: false,
        hasPermissionTransfer: false,
      };
  },
  computed: {
    ...mapGetters({
      role: 'Auth/getRole',
    }),
  },
  methods: {
    ...mapActions({
      hasRole: 'User/hasRole',
    }),
    toCollect(){
        this.$router.push({ path: `/customer/digitalAccount/pix/myArea/charge`})
    },
  },
  async created() {
    this.hasPermissionKeys = await this.hasRole(roles.CUSTOMER_WALLET_INSTANT_PAYMENT_ALIAS_WRITE.role);
    this.hasPermissionPayment = await this.hasRole(roles.CUSTOMER_WALLET_PAYMENT_BY_INSTANT_PAYMENT_WRITE.role);
    this.hasPermissionPaymentCharge = await this.hasRole(roles.CUSTOMER_WALLET_CHARGE_BY_INSTANT_PAYMENT_WRITE.role);
    this.hasPermissionTransfer = await this.hasRole(roles.CUSTOMER_WALLET_INSTANT_PAYMENT_TRANSFER_WRITE.role);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/pix.scss';
</style>
