<template>
  <v-container>
    <ModalDigitalAccount v-if="showModal" />
    <v-row v-show="!getLoading">
      <v-col cols="12" md="6" class="presentation-column pl- md-12 pb-md-0">
        <v-col align="left" cols="12" class="pb-10" v-if="$vuetify.breakpoint.smAndDown">
          <img src="@/assets/pix.png" alt="" />
        </v-col>
        <v-col class="title">
          Conheça o Pix!
        </v-col>
        <v-col cols="12" xl="8" md="12" class="text">
          O Pix é uma nova forma de fazer transações que veio como uma alternativa às tranferências
          e pagamentos atuais.
        </v-col>
        <v-col align="center" cols="12" md="12" xl="7" class="mockup pb-0">
          <img class="img-mockup" src="@/assets/mockup_pix.png" alt="" />
        </v-col>
      </v-col>
      <v-col cols="12" md="6">
        <v-col align="left" cols="12" class="pb-10" v-if="$vuetify.breakpoint.mdAndUp">
          <img src="@/assets/pix.png" alt="" />
        </v-col>
        <v-col cols="12" class="title second-title">
          Muito mais prático
        </v-col>
        <v-col md="8" cols="12" class="text pt-0">
          Você consegue receber e transferir sem precisar de todos os dados da conta.<br />
          Para isso você vai precisar apenas de uma chave Pix, que é um dado simples que irá
          identificar a conta.
        </v-col>
        <v-col cols="12" md="7" class="text-contrast">
          A chave pode ser um CNPJ, celular, e-mail ou uma chave aleatória
        </v-col>
        <v-col cols="12" md="5" class="button" v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn class="btn-register" dark rounded color="#D70472" block @click="registerKey"
            >Cadastrar chaves</v-btn
          >
        </v-col>
      </v-col>
      <v-col cols="10" class="pt-0" v-if="$vuetify.breakpoint.mdAndUp">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5" class="pb-0">
        <v-col cols="12" class="title">
          Já cadastrou sua chave?
        </v-col>
        <v-col cols="12" class="text-thumbs pt-0">
          Você pode cadastrar sua chave Pix para receber pagamentos na sua conta Finago e emitir QR
          codes. Cada chave é vinculada a uma única conta e você pode ter até 20 chaves por conta.
        </v-col>
        <v-col cols="6" class="button" v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn class="btn-register" dark rounded color="#D70472" block @click="registerKey"
            >Cadastrar chaves</v-btn
          >
        </v-col>
      </v-col>
      <v-col cols="12" md="7" class="thumbs">
        <v-row>
          <div class="thumb-row">
            <v-col cols="6" md="3" align="center" class="single-thumb first-thumb">
              <v-col align="center" cols="12" class="pb-0">
                <img src="@/assets/clock-icon.png" width="65" alt="" />
              </v-col>
              <v-col cols="12" class="title pb-1 pt-1">
                Rápido
              </v-col>
              <v-col cols="12" align="center" class="text-thumb pa-0">
                Faça pagamentos<br />instântaneos
              </v-col>
            </v-col>
            <v-col cols="6" md="3" align="center" class="single-thumb">
              <v-col align="center" cols="12" class="pb-0">
                <img src="@/assets/pig-icon.png" width="65" alt="" />
              </v-col>
              <v-col cols="12" class="title pb-1 pt-1">
                Econômico
              </v-col>
              <v-col cols="10" align="center" class="text-thumb pa-0">
                Você não paga<br />nenhuma taxa
              </v-col>
            </v-col>
            <v-col cols="6" md="3" align="center" class="single-thumb pl-1 pr-1">
              <v-col align="center" cols="12" class="pb-0">
                <img src="@/assets/benefit-icon.png" width="65" alt="" />
              </v-col>
              <v-col cols="12" class="title pb-1 pt-1">
                Conveniência
              </v-col>
              <v-col cols="12" align="center" class="text-thumb pa-0">
                Transações 24h, todos<br />os dias, inclusive feriados
              </v-col>
            </v-col>
          </div>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        align="center"
        class="pa-0 pt-10 pt-md-0"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-divider></v-divider>
        <v-col md="7" cols="12" class="button-account pl-5 pr-5">
          <v-btn class="btn-reset" @click="registerKey" rounded color="#D70472" block x-large
            >Cadastrar Chave</v-btn
          >
        </v-col>
        <v-divider></v-divider>
        <v-col md="7" cols="12" class="button-back pl-5 pr-5">
          <v-btn class="btn-reset" @click="backHome" rounded color="#8C8C8C" block x-large outlined
            >Voltar para Home</v-btn
          >
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ModalDigitalAccount from "../../commons/ModalDigitalAccount.vue";

export default {
  components: {
    ModalDigitalAccount
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    ...mapGetters({
      role: "Auth/getRole",
      getLoading: "Loading/getLoading",
      showPasswordModal: "DigitalAccount/getShowPasswordModal",
      getDigitalAccount: "DigitalAccount/getAccount"
    })
  },
  methods: {
    backHome() {
      const home = "home";
      return this.$router.push({ path: `/customer/${home}` });
    },
    registerKey() {
      this.$router.push({ path: `/customer/digitalAccount/pix/myKeys` });
    }
  },
  async created() {
    this.showModal = !this.getDigitalAccount.hasPassword;
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/pix.scss";
</style>
