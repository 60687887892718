<template>
  <v-container>
    <MyArea v-if="keyExists"/>
    <LandingPage v-else/>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LandingPage from './LandingPage';
import MyArea from './MyArea'

export default {
  components: {
    LandingPage,
    MyArea,
  },
  data() {
    return {
      keyExists: Boolean,
    }
  },
  computed: {
    ...mapGetters({
      role: 'Auth/getRole',
      getKeyExists: 'Pix/getKeyExists',
      getDataKeys:'Pix/getAllDataKeys',
      getModalStatus: 'Pix/getModalStatus'
    }),
  },
  methods: {
    ...mapActions({
      getAllDataKeys: 'Pix/getAllPixKeys',
      checkStatusModal: 'Pix/checkStatusModal',
      updateStatusModal: 'Pix/updateStatusModal',
    }),
    ...mapMutations({
      setKeyExists: 'Pix/SET_KEY_EXISTS',
    }),
    async consultKey() {
      await this.getDataKeys
      if(this.getDataKeys.items[0].alias.length !== 0 && this.getDataKeys.items[0].alias !== null){
        this.setKeyExists(true)
        this.keyExists = true
      } else {
        this.setKeyExists(false)
        this.keyExists = false
      }
    },
  },
  async created() {
    await this.getAllDataKeys({
      "page":1,
      "perPage":20
    })
    await this.checkStatusModal()
    await this.consultKey()
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/pix.scss';
</style>
